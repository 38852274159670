<template>
  <div>
    <h1 v-if="type === 'Edit' || type === 'Add'">Set coordinate</h1>
    <h1 v-if="type === 'details'">The Location</h1>
    <google-map
      v-if="type === 'Edit' || type === 'Add'"
      :center="coordinates"
      mapTypeControl="true"
      :options="{ gestureHandling: 'cooperative',minZoom: 2, maxZoom: 20 }"
      :zoom="7"
      map-type-id="roadmap"
      style="width: 100%; height: 360px; margin-bottom: 1rem"
      ref="mapRef"
    >
      <google-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="false"
        :draggable="false"
      />
    </google-map>
    <google-map
      v-if="type === 'details'"
      :center="coordinates"
      mapTypeControl="true"
      :zoom="10"
      map-type-id="roadmap"
      style="width: 100%; height: 500px; margin-bottom: 1rem"
      ref="mapRef"
    >
      <div v-if="locations">
        <google-marker
          :key="index"
          v-for="(m, index) in detailsLocations"
          :position="m"
          :clickable="false"
          :draggable="false"
        />
      </div>
    </google-map>
  </div>
</template>

<script>
export default {
  emits: ['resiveLatLng'],
  props: ['type', 'data', 'locations', 'coordinatesUser', 'canEdit', 'resetMap'],
  data: () => {
    return {
      isEdited: false,
      map: null,
      detailsLocations: null,
      coordinates: {
        lat: 0,
        lng: 0,
      },
      markers: [
        {
          position: {
            lat: 0,
            lng: 0,
          },
        },
      ],
    }
  },
  created() {
    console.log('this.type', this.type)
    if (this.type === 'Edit') {
      if (this.data?.latitude && this.data?.longitude) {
        this.coordinates.lat = parseFloat(this.data.latitude)
        this.coordinates.lng = parseFloat(this.data.longitude)
      } else {
        this.coordinates.lat = 31.0
        this.coordinates.lng = 35.8594264
      }
      this.markers[0].position.lat = this.coordinates.lat
      this.markers[0].position.lng = this.coordinates.lng
    } else if (this.type === 'Add') {
      this.$getLocation({})
        .then(coordinates => {
          this.coordinates = coordinates
        })
        .catch(error => {
          console.log(error);
        })
      if (!this.coordinates.lat) {
        this.markers[0].position.lat = 32
        this.markers[0].position.lng = 36
      }
    } else if (this.type === 'details') {
      if (this.locations.length > 0) {
        this.detailsLocations = [...this.locations].map(location => {
          return {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          }
        })
        this.coordinates.lat = parseFloat(this.detailsLocations[0].lat)
        this.coordinates.lng = parseFloat(this.detailsLocations[0].lng)
      }
    }
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then(map => (this.map = map))
  },
  computed: {
    mapCoordinates() {
      if (!this.map) {
        return {
          lat: 0,
          lng: 0,
        }
      }
      return {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      }
    },
  },
  watch: {
    mapCoordinates(newV, oldV) {
      this.$emit('resiveLatLng', newV)
      this.markers[0].position.lat = this.map.getCenter().lat()
      this.markers[0].position.lng = this.map.getCenter().lng()
      if (this.type === 'details') {
        this.coordinates.lat = parseFloat(this.detailsLocations[0].lat)
        this.coordinates.lng = parseFloat(this.detailsLocations[0].lng)
      }
    },
    coordinatesUser(newV, oldV) {
      if (newV.lat === null) {
        this.$getLocation({})
          .then(coordinates => {
            this.coordinates = coordinates
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    resetMap(newV, oldV) {
      if (newV) {
        this.coordinates.lat = 0
        this.coordinates.lng = 0
        this.$emit('update:resetMap', false)
      }
    },
    locations(newV, oldV) {
      if (this.locations.latitude && this.locations.longitude && !this.canEdit) {
        this.coordinates.lat = parseFloat(this.locations.latitude)
        this.coordinates.lng = parseFloat(this.locations.longitude)
      }
    },
  },
}
</script>

import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function usekitchensList() {
  const kitchenListTable = ref([])

  const tableColumns = [
    { text: 'kitchen Name', value: 'name' },
    { text: 'MOBILE NUMBER', value: 'contact_person' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
const tableColumnsForChiefs = [
  { text: 'Chef Name', value: 'name' },
  { text: 'MOBILE NUMBER', value: 'mobile_number' },
  { text: 'Rate', value: 'rate' },
  {
    text: 'ACTIONS',
    value: 'actions',
    align: 'center',
    sortable: false,
  },
]
  const searchQuery = ref('')
  const phoneFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalkitchenListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const selectedRows = ref([])

  // fetch data
  const fetchkitchens = () => {
    options.value.q = searchQuery.value
    store
      .dispatch('app-kitchen/fetchkitchens', {
        options: options.value,
        status: statusFilter.value,
        phone: phoneFilter.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total } = response.data.data
        console.log('fetchkitchens', filteredData)
        kitchenListTable.value = filteredData
        totalkitchenListTable.value = total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }

  watch([searchQuery, phoneFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchkitchens()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // As we changes this field to phone number instead of Role; this function will no longer be needed
  // *===============================================---*

  const resolvekitchenRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolvekitchenstatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolvekitchenTotalIcon = total => {
    if (total === 'Total kitchens') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid kitchens') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active kitchens') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending kitchens') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    tableColumnsForChiefs,
    kitchenListTable,
    tableColumns,
    searchQuery,
    phoneFilter,
    planFilter,
    statusFilter,
    totalkitchenListTable,
    loading,
    options,
    selectedRows,
    fetchkitchens,
  }
}

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchkitchens(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/kitchens', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchkitchen(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/kitchens/view/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    reFetchkitchen(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/kitchens/view/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    kitchensList(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/kitchens?options={"itemsPerPage": "-1", "page":"1"}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addkitchen(ctx, kitchenData) {
      const bodyFormData = new FormData()
      bodyFormData.append('name', kitchenData.name)
      bodyFormData.append('contact_person', kitchenData.mobile_number)
      bodyFormData.append('emergency_number', kitchenData.emergency_number)
      bodyFormData.append('start_work_time', kitchenData.start_work_time)
      bodyFormData.append('end_work_time', kitchenData.end_work_time)
      bodyFormData.append('image', kitchenData.image)
      bodyFormData.append('latitude', kitchenData.latitude)
      bodyFormData.append('longitude', kitchenData.longitude)
      bodyFormData.append('work_days', kitchenData.work_days)
      bodyFormData.append('street_name', kitchenData.street_name)
      bodyFormData.append('building_number', kitchenData.building_number)
      bodyFormData.append('username', kitchenData.username)
      bodyFormData.append('password', kitchenData.password)
      
      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/kitchens/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
    editkitchen(_ctx, kitchenData) {
      // Defining a new formData instance and appending the needed properties
      const bodyFormData = new FormData()
      bodyFormData.append('id', kitchenData.id)
      bodyFormData.append('name', kitchenData.name)
      bodyFormData.append('contact_person', kitchenData.contact_person)
      bodyFormData.append('emergency_number', kitchenData.emergency_number)
      bodyFormData.append('start_work_time', kitchenData.start_work_time)
      bodyFormData.append('end_work_time', kitchenData.end_work_time)
      bodyFormData.append('latitude', kitchenData.latitude)
      bodyFormData.append('longitude', kitchenData.longitude)
      bodyFormData.append('street_name', kitchenData.street_name)
      bodyFormData.append('building_number', kitchenData.building_number)
      bodyFormData.append('work_days', kitchenData.work_days)

      if (kitchenData.username) bodyFormData.append('username', kitchenData.username)
      if (kitchenData.password) bodyFormData.append('password', kitchenData.password)
      if (kitchenData.image && kitchenData.image.type) bodyFormData.append('image', kitchenData.image)


      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/kitchens/update',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
    deletekitchen(_ctx, id) {
      const bodyFormData = new FormData()
      bodyFormData.append('id', id)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/kitchens/delete/',
          data: bodyFormData,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
  },
}
